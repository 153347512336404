import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useStoreState } from "easy-peasy";
import Spinner from "../../components/Spinner";
import patientService from "../../services/patient";

function Ranking() {
  return (
    <div className="ranking">
      <UserRanking />
      <AreaRanking />
    </div>
  );
}

function UserRanking() {
  const [isLoading, setIsLoading] = useState(false);
  const [patient] = useState(null);
  const [patientPoints, setPatientPoints] = useState(0);
  const [patientRanking, setPatientRanking] = useState(1);
  const [participants, setParticipants] = useState(0);
  const user = useStoreState((state) => state.user);
  const fullName = getFullName(user);
  const company = getCompany(user);
  const patientArea = getAreaPatient(user);

  useEffect(() => {
    async function getInfoPatient() {
      const patientID = user.patient;

      if (patientID) {
        try {
          setIsLoading(true);
          patientService.getPoints(patientID).then((stats) => {
            setPatientRanking(stats.ranking);
            setPatientPoints(stats.total);
            setParticipants(stats.participants);
          });
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    }

    getInfoPatient();
  }, [user]);

  function getCompany() {
    return patient && patient.company && patient.company.name;
  }

  function getAreaPatient() {
    return patient && patient.company && patient.company.area;
  }

  function getFullName(user) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <h1>Ranking</h1>
      <div className="user-ranking">
        {isLoading && <Spinner />}

        {!isLoading && (
          <div>
            <div className="header">
              <img alt="avatar" className="avatar" src="/avatar.jpg" />
              <div className="content">
                <div className="full-name">{fullName}</div>
                {company && (
                  <div className="company">
                    {company} - {patientArea}
                  </div>
                )}
              </div>
            </div>

            <div className="numbers">
              <div className="points">
                <span className="number">{patientPoints}</span>
                <span className="description">Puntos</span>
              </div>
              <div className="position">
                <span className="number">{patientRanking}</span>
                <span className="description">Puesto</span>
              </div>
            </div>

            <div className="total-participants">
              Total de Participantes: {participants}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function AreaRanking() {
  const [isLoading, setIsLoading] = useState(false);
  const [missingCompany, setMissingCompany] = useState(false);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const sectors = await patientService.getScoresBySectors();
        const areas = {};
        Object.values(sectors).forEach((v) => {
          v.forEach((s) => {
            if (!areas[s._id]) {
              areas[s._id] = {
                name: s._id,
                points: s.points,
              };
            } else {
              areas[s._id].points += s.points;
            }
          });
        });

        const ranking = Object.values(areas).sort((a, b) => {
          if (a.points < b.points) {
            return 1;
          }

          return -1;
        });

        setAreas(ranking);
      } catch (error) {
        setMissingCompany(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  if (missingCompany) {
    return (
      <h2>
        La informacion no esta disponible ya que el usuario no tiene empresa
        asignada
      </h2>
    );
  }

  return (
    <div className="areas-ranking">
      <h1>Ranking por Áreas</h1>

      {areas.map((area, index) => (
        <Area area={area} index={index} key={area.name}></Area>
      ))}
    </div>
  );
}

function Area(props) {
  const { area, index } = props;
  return (
    <div className="area-ranking">
      <div className="ranking">
        <div className="position">{index + 1}</div>
        <div className="area-name">{area.name}</div>
        <div className="points">{area.points}</div>
      </div>
    </div>
  );
}

export { Ranking, AreaRanking };
