import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/Spinner";
import rewardService from "../../services/rewards";
import config from "../../services/config";

export default function Rewards() {
  const [rewards, setRewards] = useState([]);
  const [rewardsByType, setRewardsByType] = useState([]);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const notifyError = () => toast("Error al cargar lista de premios");

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const rewardsFetch = await rewardService.getRewards();
        setRewards(rewardsFetch);
        setType("single");
      } catch (error) {
        notifyError();
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setRewardsByType(rewards.filter((reward) => reward.type === type));
  }, [type, rewards]);

  const selectType = (e) => {
    const typeSelected = e.target.textContent;
    const types = document.querySelectorAll(".rewards .nav-sections");
    types.forEach((t) => {
      t.textContent === typeSelected
        ? t.classList.remove("noSelected")
        : t.classList.add("noSelected");
    });

    typeSelected === "Individuales" ? setType("single") : setType("group");
  };

  const checkTypeExistence = (type) => {
    return rewards.some((reward) => reward.type === type);
  };

  return (
    <div className="rewards">
      <ToastContainer toastClassName="customToaster" hideProgressBar />
      <h1>Premios</h1>
      <div className="reward-types">
        <header className="topnav">
          <div className="items">
            {checkTypeExistence("single") && (
              <div className="nav-sections type" onClick={selectType}>
                Individuales
              </div>
            )}
            {checkTypeExistence("group") && (
              <div
                className="nav-sections noSelected type"
                onClick={selectType}
              >
                Grupales
              </div>
            )}
          </div>
        </header>
      </div>

      {isLoading && <Spinner />}
      {!isLoading && rewardsByType && (
        <div className="rewards-list">
          {rewardsByType.map((reward) => (
            <Reward key={reward._id} reward={reward} />
          ))}
        </div>
      )}
    </div>
  );
}

function Reward({ reward }) {
  return (
    <div className="reward">
      <div className="position">
        <h2>{reward.orderTitle}</h2>
      </div>
      <div className="reward-description">
        <div className="photo">
          <img
            src={
              reward.image
                ? `${config.s3URL}/${reward.image.value}`
                : "/rewards/no-image.png"
            }
            alt={reward.image ? reward.name : "Unavailable"}
            className={reward.image ? "" : "image-unavailable"}
          />
        </div>
        <div className="data">
          <div className="name">{reward.title}</div>
          <div className="description">{reward.description}</div>
        </div>
      </div>
    </div>
  );
}
