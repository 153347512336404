import api from "./api";

const patientService = {};

patientService.getScoresByTreatment = function (id) {
  return api.get("/scores/by-treatments");
};

patientService.getScoresBySectors = function (id) {
  return api.get("/scores/by-sectors");
};

patientService.getPoints = function () {
  return api.get(`scores/me`);
};

patientService.getPatients = function ({ start, limit } = {}) {
  const url = `scores?`;
  const startQuery = start ? `&offset=${start}` : ``;
  const limitQuery = limit ? `&limit=${limit}` : ``;
  return api.get(`${url}${startQuery}${limitQuery}`);
};

export default patientService;
