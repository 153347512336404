import React from "react";
import { useHistory } from "react-router";
import Arrow from "./Arrow";

export default function BackArrow() {
  const history = useHistory();

  function reverseBack() {
    history.goBack();
  }

  return <Arrow direction={"left"} action={reverseBack} />;
}
