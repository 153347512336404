import React from "react";
import { useParams } from "react-router";
import BackArrow from "../../components/BackArrow";

export default function Video() {
  const { id } = useParams();

  return (
    <div>
      <BackArrow />
      <div className="video">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}
