export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3000";
export const audience = process.env.REACT_APP_AUDIENCE || "scores";

const config = {
  apiUrl,
  audience,
  youtubeBaseUrl: "https://www.googleapis.com/youtube/v3/playlistItems",
  youtubePlaylistId: process.env.REACT_APP_YOUTUBE_API_PLAYLIST_ID || "",
  youtubeToken: process.env.REACT_APP_YOUTUBE_API_TOKEN || "",
  s3URL: process.env.REACT_APP_S3_URL || "",
};

export default config;
