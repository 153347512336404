import React, { useState, useEffect } from "react";

export default function Arrow({ action, direction, style }) {
  const [displayArrow, setDisplay] = useState(true);
  const display = style ? style.display : true;

  useEffect(() => {
    setDisplay(display);
  }, [display]);

  return (
    <span
      className={`arrow ${direction}`}
      onClick={action}
      style={displayArrow ? { display: "block" } : { visibility: "hidden" }}
    />
  );
}
