import { action } from "easy-peasy";
import { thunk } from "easy-peasy";
import authService from "../services/auth";

const state = {
  user: authService.getUser(),

  token: authService.getToken(),

  removeUser: action((state) => {
    authService.clearUser();
    state.user = null;
    state.token = null;
  }),

  setUser: action((state, user) => {
    state.user = user;
  }),

  setToken: action((state, token) => {
    state.token = token;
  }),

  authenticate: thunk(async (actions, payload) => {
    const res = await authService.authenticate(payload);
    actions.setUser(res.user);
    actions.setToken(res.token);

    return res;
  }),
};

export default state;
