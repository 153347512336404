import jwt from "jsonwebtoken";
import api from "./api";

const authService = {};
const localStorageKey = "auth";

authService.authenticate = function ({ email, password }) {
  return api.post("/auth/token", { email, password }).then((res) => {
    localStorage.setItem(localStorageKey, JSON.stringify(res));
    return res;
  });
};

authService.getUser = function () {
  const authData = localStorage.getItem(localStorageKey);
  const parsed = authData && JSON.parse(authData);
  return parsed && parsed.user;
};

authService.getToken = function () {
  const authData = localStorage.getItem(localStorageKey);
  const parsed = authData && JSON.parse(authData);
  return parsed && parsed.token.replace("Bearer ", "");
};

authService.clearUser = function () {
  localStorage.clear(localStorageKey);
};

authService.isAuthenticated = function () {
  const token = authService.getToken();
  const decodedToken = jwt.decode(token);

  if (!token || !decodedToken) {
    authService.clearUser();
    return false;
  }

  const isLegacyToken = !decodedToken.exp || !decodedToken.aud;
  const isExpiredToken = Math.floor(Date.now() / 1000) > decodedToken.exp;

  if (isLegacyToken || isExpiredToken) {
    authService.clearUser();
    return false;
  }

  return true;
};

authService.forgotPassword = function ({ email }) {
  return api.put(`/users/${email}/password-reset`).then((res) => {
    return res;
  });
};

export default authService;
