import React, { useState, useEffect } from "react";
import { VictoryPie, VictoryLabel } from "victory";
import Spinner from "../../components/Spinner";

export default function PieChart({ size, array, showPoints = true }) {
  const [data, setData] = useState([]);

  function getPercent(array) {
    let newArray = array;
    let totalPoints = 0;
    array.forEach((elem) => {
      totalPoints += elem.y;
    });
    newArray.forEach((elem) => {
      elem.percentage = ((elem.y / totalPoints) * 100).toFixed(2);
    });
    return newArray;
  }

  function formatData(array) {
    let newArray = [];
    array.forEach((elem, index) => {
      let newObj = {
        x: elem.area,
        y: elem.points,
      };
      newArray.push(newObj);
    });
    return newArray;
  }

  function getColorsScale() {
    let colors = data.length + 1;
    let colorPercentage = 1 / colors;
    let colorArray = [];
    for (let i = 0; i < colors; i++) {
      colorArray.push(`rgba(239, 94, 38, ${1 - i * colorPercentage})`);
    }
    return colorArray;
  }

  useEffect(() => {
    setData(getPercent(formatData(array)));
  }, [array]);

  if (data.length > 0) {
    return (
      <VictoryPie
        colorScale={getColorsScale()}
        data={data}
        height={size}
        labelRadius={({ innerRadius }) => innerRadius + size / 6}
        labels={({ datum }) => [
          datum.x,
          `${showPoints ? datum.y + " puntos" : ""}`,
          `${datum.percentage} %`,
        ]}
        labelComponent={
          <VictoryLabel style={[{ fontSize: size / 30, fill: "white" }]} />
        }
      ></VictoryPie>
    );
  }
  return <Spinner></Spinner>;
}
