import jwtDecode from "jwt-decode";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import { audience } from "../../services/config";
import Spinner from "../../components/Spinner";
import PasswordInput from "../../components/PasswordInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authService from "../../services/auth";

export default function Login({ forgot }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const authenticate = useStoreActions((actions) => actions.authenticate);
  const removeUser = useStoreActions((actions) => actions.removeUser);
  const history = useHistory();
  const forgotPasswordPath = forgot || false;
  const notifyError = () => toast("Ingrese nuevamente usuario y contraseña");

  useEffect(() => {
    const disableButton = !(email.trim() || password.trim());
    setIsButtonDisabled(disableButton);
  }, [email, password]);

  async function handleLogin() {
    if (forgotPasswordPath) {
      try {
        authService.forgotPassword({ email });
      } catch (error) {
        console.log(error);
      }
      history.push("/");
    } else {
      setIsButtonDisabled(true);

      try {
        setIsLoading(true);
        const { token } = await authenticate({ email, password });
        const payload = jwtDecode(token);

        if (!payload.aud.includes(audience)) {
          removeUser();
          history.push("/401");
          // return Promise.reject();
        }
        history.push("/");
      } catch (error) {
        console.log(error);
        notifyError();
        setIsLoading(false);
      }
    }
  }

  function handleKeyPress(e) {
    if ((e.keyCode === 13 || e.which === 13) && !isButtonDisabled) {
      handleLogin();
    }
  }

  return (
    <div className="login">
      <ToastContainer toastClassName="customToaster" hideProgressBar />
      {isLoading && (
        <div className="loader">
          <Spinner></Spinner>
        </div>
      )}
      <img className="logo" alt="logo" src="/asebien/gray-logo.png" />
      <input
        className="input"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={(e) => handleKeyPress(e)}
      />
      {!forgotPasswordPath && (
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
      )}
      <button
        className="button"
        disabled={isButtonDisabled}
        type="button"
        onClick={handleLogin}
      >
        {forgotPasswordPath ? "Reestablecer Contraseña" : "Iniciar Sesión"}
      </button>
      {!forgotPasswordPath && (
        <div className="passwordForgot">
          <a href="/forgot-password">¿Olvidaste tu contraseña?</a>
        </div>
      )}
    </div>
  );
}
