import trae from "trae";
import authService from "./auth";
import { apiUrl } from "./config";

trae.defaults({ baseUrl: apiUrl });

const getErrorMessage = (err) => {
  switch (err.status) {
    case 404:
      return "No se han encontrado recursos";
    case 403:
      return "Este usuario no tiene permisos para acceder al recurso solicitado";
    case 400:
      return "Alguno de los campos introducidos es incorrecto";
    default:
      return "Se ha producido un error, intentelo nuevamente";
  }
};

const beforeMiddleware = function (config) {
  const token = authService.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const fulfillMiddleware = function (res) {
  return res.data;
};

const handleError = (err) => {
  if (err.status === 401) {
    authService.clearUser();
  }

  const message = getErrorMessage(err);
  // TODO: show toast
  console.log("error", message); // eslint-disable-line
  return Promise.reject(err);
};

const handlers = {
  get: (...args) => {
    return trae.get(...args).catch((err) => {
      return handleError(err);
    });
  },

  post: (...args) => {
    return trae.post(...args).catch((err) => {
      return handleError(err);
    });
  },

  put: (...args) => {
    return trae.put(...args).catch((err) => {
      return handleError(err);
    });
  },

  delete: (...args) => {
    return trae.delete(...args).catch((err) => {
      return handleError(err);
    });
  },
};

trae.before(beforeMiddleware);
trae.after(fulfillMiddleware);

export default handlers;
