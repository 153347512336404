import useQuery from "../../util/useQuery";
import authService from "../../services/auth";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

// TODO: check email
export default function Auth() {
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    async function login() {
      const email = query.get("email");
      const password = query.get("password");

      try {
        await authService.authenticate({ email, password });
        history.push("/");
      } catch (error) {
        console.log("error"); // eslint-disable-line
      }
    }

    login();
  }, [history, query]);

  return null;
}
