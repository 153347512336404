import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Spinner from "../../components/Spinner";
import Table from "./Table";
import PieChart from "./PieChart";
import { AreaRanking } from "../home/Ranking";
import patientService from "../../services/patient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Admin() {
  const [patients, setPatients] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await patientService.getPatients({
          start: 0,
          limit: undefined,
        });
        setPatients(response);
      } catch (error) {}
    }
    fetchData();
  }, []);

  if (!patients) {
    return <Spinner></Spinner>;
  }

  return (
    <div className="adminPanel">
      <ToastContainer toastClassName="customToaster" hideProgressBar />
      <div className="upperPannel ">
        <div>
          <h1>Distribución por género</h1>
          <GenerosPieChart data={patients} />
        </div>
        <div>
          <h1>Uso de la aplicación (últimos 30 días)</h1>
          <UsagePieChart data={patients} />
        </div>
        <div>
          <AreaRanking />
        </div>
      </div>
      <div className="bottomPannel">
        <PatientsTable data={patients} />
      </div>
    </div>
  );
}

function PatientsTable({ data }) {
  // const maxRows = 5;
  const [patients, setPatients] = useState([]);
  // const [pages, setPages] = useState(undefined);
  // const [isLoading, setIsLoading] = useState(false);
  const headers = useMemo(
    () => [
      { "Nombre Completo": "fullName" },
      { Género: "gender" },
      { Puntos: "points" },
      { Ranking: "ranking" },
    ],
    []
  );
  //const [currentPage, setCurrentPage] = useState(0);
  const notifyError = () => toast("Error al cargar tabla de pacientes");

  const formatResponse = useCallback(
    (array) => {
      let newArray = [];
      array.docs.sort((a, b) => {
        if (a.stats.total <= b.stats.total) {
          return 1;
        }

        return -1;
      });

      array.docs.forEach((elem, index) => {
        let newElem = { ranking: index + 1 };
        for (let i in elem) {
          headers.forEach((header) => {
            if (i === Object.values(header)[0]) {
              newElem[i] = elem[i];
            }
          });

          if (i === "firstName") {
            newElem.fullName = `${elem.lastName}, ${elem.firstName}`;
          }

          if (i === "gender") {
            newElem.gender = elem[i] === "m" ? "Hombre" : "Mujer";
          }

          if (i === "stats") {
            newElem.points = elem[i].total; //Hardcodeado el nombre de la prop points
          }
        }
        newArray.push(newElem);
      });

      return newArray;
    },
    [headers]
  );

  useEffect(() => {
    try {
      setPatients(formatResponse(data));
    } catch (error) {
      notifyError();
    }
  }, [data, formatResponse]);

  return (
    <Fragment>
      <h1>Ranking de Participantes</h1>
      <Table
        data={patients}
        headers={headers}
        //pages={pages}
        //isLoading={isLoading}
        /* leftArrowAction={arrowAction("left")}
        rightArrowAction={arrowAction("right")} */
        //currentPage={currentPage}
      ></Table>
    </Fragment>
  );
}

function GenerosPieChart({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [areasArray, setAreasArray] = useState([]);
  const notifyError = () => toast("Error al cargar distribución por género");
  const size = 300;

  useEffect(() => {
    try {
      setIsLoading(true);

      const formatResponse = { m: 0, f: 0 };
      data.docs.forEach((elem) => {
        // let elemPoints = Object.values(elem.stats).reduce((t, n) => t + n, 0);
        formatResponse[elem.gender] += elem.stats.total;
      });
      setAreasArray([
        { area: "Hombres", points: formatResponse.m },
        { area: "Mujeres", points: formatResponse.f },
      ]);
    } catch (error) {
      notifyError();
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return <PieChart array={areasArray} size={size}></PieChart>;
}

function UsagePieChart({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [areasArray, setAreasArray] = useState([]);
  const notifyError = () => toast("Error al cargar uso de la aplicación");
  const size = 300;

  useEffect(() => {
    try {
      setIsLoading(true);
      const numDaysBetweenToday = (d1) => {
        const diff = Math.abs(d1.getTime() - new Date().getTime());
        return diff / (1000 * 60 * 60 * 24);
      };

      const res = [
        { area: "SI", points: 0 },
        { area: "NO", points: 0 },
      ];

      data.docs.forEach((p) => {
        if (
          p.lastActivityAt &&
          numDaysBetweenToday(new Date(p.lastActivityAt)) <= 30
        ) {
          res[0].points += 1;
        } else {
          res[1].points += 1;
        }
      });
      setAreasArray(res);
    } catch (error) {
      notifyError();
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <PieChart array={areasArray} size={size} showPoints={false}></PieChart>
  );
}
