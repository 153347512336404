import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import config from "../../services/config";
import BackArrow from "../../components/BackArrow";

function VideoItem({ video }) {
  return (
    <a
      href={`${window.location.href}/${video.snippet.resourceId.videoId}`}
      className="videoItem"
    >
      <img
        src={video.snippet.thumbnails.medium.url}
        alt={video.snippet.title}
      ></img>
      <h2>{video.snippet.title}</h2>
      <p>{video.snippet.description}</p>
    </a>
  );
}

export default function VideosList() {
  const baseUrl = config.youtubeBaseUrl;
  const playlistId = config.youtubePlaylistId;
  const token = config.youtubeToken;
  const [videos, setVideos] = useState();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${baseUrl}?playlistId=${playlistId}&key=${token}&part=snippet&maxResults=100`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setVideos(result.items))
      .catch((error) => console.log("error", error)); // eslint-disable-line
  }, [token, baseUrl, playlistId]);

  if (!videos) {
    return <Spinner />;
  }

  return (
    <div>
      <BackArrow />
      <div className="videosIndex">
        <h1>Videos</h1>
        <div className="videosList">
          {videos.map((video) => (
            <VideoItem key={video.id} video={video} />
          ))}
        </div>
      </div>
    </div>
  );
}
