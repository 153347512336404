import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Auth from "./pages/public/Auth";
import ForgotPassword from "./pages/public/ForgotPassword";
import Login from "./pages/public/Login";
import Home from "./pages/home";
import authService from "./services/auth";
import TopNav from "./components/TopNav";
import VideosList from "./pages/videos/index";
import Video from "./pages/video/index";
import Admin from "./pages/admin/index";

import Error401 from "./pages/public/Error401";

import "./styles/index.scss";

function App() {
  const user = useStoreState((state) => state.user);

  return (
    <Router>
      <TopNav />
      <main>
        <Switch>
          <Route path="/auth">
            <Auth />
          </Route>
          <Route path="/login" public={true}>
            <Login />
          </Route>
          <Route path="/401">
            <Error401 />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <PrivateRoute path="/videos/:id">
            <Video />
          </PrivateRoute>
          <PrivateRoute path="/videos">
            <VideosList />
          </PrivateRoute>
          <PrivateRoute path="/">
            {user?.role === "Patient" ? <Home /> : <Admin />}
          </PrivateRoute>
        </Switch>
      </main>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = authService.isAuthenticated();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
