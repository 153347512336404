import { Link } from "react-router-dom";
import React from "react";
import { useStoreState } from "easy-peasy";
import { useStoreActions } from "easy-peasy";

export default function TopNav() {
  const isLoggedIn = !!useStoreState((state) => state.user);
  const removeUser = useStoreActions((actions) => actions.removeUser);
  const user = useStoreState((state) => state.user);

  function signOut() {
    removeUser();
  }

  if (!isLoggedIn) {
    return null;
  }

  return (
    <header className="topnav">
      <div className="items">
        <Link to="/" className="logo">
          <img alt="logo" src="/asebien/gray-logo.png" />
        </Link>
        {user?.role === "Patient" && (
          <Link to="/videos" className="nav-sections">
            Videos
          </Link>
        )}
      </div>

      <Link onClick={signOut} className="user-session" to="/login">
        Cerrar Sesión
        {/* <img alt="avatar" className="logo" src="/avatar.jpg" /> */}
      </Link>
    </header>
  );
}
