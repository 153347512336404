import React from "react";
import Rewards from "./Rewards";
import { Ranking } from "./Ranking";

export default function Home() {
  return (
    <div className="home">
      <Ranking />
      <Rewards />
    </div>
  );
}
