import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
//import Arrow from "../../components/Arrow";

export default function Table({
  data,
  headers,
  //pages,
  isLoading,
  //currentPage,
  //leftArrowAction,
  //rightArrowAction,
}) {
  const columnsGrid = "1fr ".repeat(headers.length);
  const [filter, setFilter] = useState();
  const [tableData, setTableData] = useState();

  useEffect(() => {
    setTableData(data);
    if (filter) {
      const orderedData = data.filter(function (obj) {
        return obj.fullName.indexOf(filter) !== -1;
      });
      /* const orderedData = []

      data.forEach(element => {
        if (element.fullName.indexOf(filter) !==1) {
          orderedData.push(element)
        }
      }); */

      setTableData(orderedData);
    }
  }, [filter, data]);

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  if (data.length > 0) {
    return (
      <div>
        <table className="table" style={{ gridTemplateColumns: columnsGrid }}>
          <thead>
            <tr>
              {headers.map((headerName, index) =>
                index === 0 ? (
                  <th key={Object.keys(headerName).toString()}>
                    <input
                      type="search"
                      placeholder={Object.keys(headerName)}
                      value={filter || ""}
                      onChange={(e) => setFilter(e.target.value)}
                    ></input>
                  </th>
                ) : (
                  <th key={Object.keys(headerName).toString()}>
                    {Object.keys(headerName)}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {tableData.map((patient, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td
                    key={`${index}_${
                      patient[Object.values(header)]
                    }${Object.values(header)}`}
                  >
                    {patient[Object.values(header)]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {/* <div className="tableFooter">
          <Arrow
            direction={"left"}
            action={leftArrowAction}
            style={{
              display: currentPage > 0 ? true : false,
            }}
          ></Arrow>
          <h1>
            {currentPage + 1} / {pages}
          </h1>
          <Arrow
            direction={"right"}
            action={rightArrowAction}
            style={{
              display: currentPage < pages - 1 ? true : false,
            }}
          ></Arrow>
        </div> */}
      </div>
    );
  }
  return <Spinner></Spinner>;
}
