import React, { useState } from "react";

export default function PasswordInput({ value, onChange, onKeyPress }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <input
        className="input"
        placeholder="Contraseña"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        type={show ? "text" : "password"}
      />
      {value ? (
        <span
          onClick={handleClick}
          style={{
            cursor: "pointer",
            fontSize: "12px",
            position: "absolute",
            paddingTop: "13px",
            paddingRight: "12px",
            right: 0,
            color: "#9b9b9b",
          }}
        >
          {show ? "Ocultar" : "Mostrar"}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}
